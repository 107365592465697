import React, {
  lazy,
  useRef,
  Suspense,
  useState,
  useEffect,
  MutableRefObject,
} from "react";
import { iCriticalBedList } from "../../interfaces";
import { useSelector, useDispatch } from "react-redux";
import { TextField, InputAdornment } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  getDate,
  titleCase,
  createApiUrl,
  CustomArrowIcon,
} from "../../utils/helperFunctions";
import {
  admDate,
  hospitals,
  layoutOptions,
  CRITICAL_BEDS_ID,
  noPatientAvailableText,
  WEBSOCKET_URL,
  APP_TYPE,
  thopNotificationAlertType,
} from "../../utils/constants";
import {
  setBedId,
  setLayout,
  setBedNumber,
  setPatientUHID,
  setAdmissionDate,
  setCurrentLocation,
  setMasterPatientDetails,
  setHasPatientDataLoaded,
  setSelectedPatientIndex,
  setSearchQuery,
  addThopNotificationMessage,
  setStatusUpdateCount,
} from "../../redux/actions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import LoadingComp from "../LoadingComp/LoadingComp";
import useGetLocations from "../../utils/useGetLocations";
import PatientDetails from "../PatientDetails/PatientDetails";
import PatientReports from "../PatientReports/PatientReports";
import useGetCriticalBeds from "../../utils/useGetCriticalBeds";
import VitalsContainer from "../VitalsContainer/VitalsContainer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useGetPatientDetails from "../../utils/useGetPatientDetails";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import "./Hospitals.css";
import useGetDoctors from "../../utils/useGetDoctors";

const PatientDashboard = lazy(
  () => import("../PatientDashboard/PatientDashboard")
);
const TreatmentAndMedications = lazy(
  () => import("../TreatmentAndMedications/TreatmentAndMedications")
);

const Hospitals = () => {
  const dispatch = useDispatch();
  const patientDetails = useSelector((state: any) => ({
    patient_fhir_id: state.patient_fhir_id,
    patient_data_loaded: state.patient_data_loaded,
    layout: state.layout,
    patient_uhid: state.patient_uhid,
    masterPatientDetails: state.masterPatientDetails,
    currentLocation: state.currentLocation,
    onlyLocations: state.onlyLocations,
    searchQuery: state?.searchQuery,
  }));
  const selectedPatientIndex = useSelector(
    (state: any) => state.selectedPatientIndex
  );
  const thopSocketRef = useRef<WebSocket | null>(null);

  const patientRefs = useRef<(HTMLDivElement | null)[]>([]);
  const patientCardRef = useRef() as MutableRefObject<HTMLDivElement>;
  const criticalBedList = useGetCriticalBeds(patientDetails.currentLocation);
  const { fetchPatientDetails } = useGetPatientDetails();
  const { fetchGetLocations } = useGetLocations();
  const { fetchGetDoctors } = useGetDoctors();
  const [isInitialRenderForPatient, setIsInitialRenderForPatient] =
    useState<boolean>(true);
  const [isInitialRenderForSearch, setIsInitialRenderForSearch] =
    useState<boolean>(true);

  const handleSelectedPatient = (patient: iCriticalBedList, index: number) => {
    if (selectedPatientIndex !== null) {
      patientCardRef.current.children[selectedPatientIndex]?.classList.remove(
        "patient-card-selected"
      );
    }
    patientCardRef.current.children[index].classList.add(
      "patient-card-selected"
    );
    dispatch(setBedId(patient?.id));
    dispatch(setBedNumber(patient?.bed_number));
    dispatch(setAdmissionDate(patient?.admission_date));
    dispatch(setSelectedPatientIndex(index));
    fetchPatientDetails(patient?.patient_id);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(event?.target?.value));
  };

  const filteredBedList = criticalBedList?.filter(
    (patient) =>
      patient?.patient_name
        ?.toLowerCase()
        ?.includes(patientDetails?.searchQuery?.toLowerCase()) ||
      patient?.patient_id
        ?.toLowerCase()
        ?.includes(patientDetails?.searchQuery?.toLowerCase())
  );

  const createThopNotificationSocketConnection = () => {
    const socket = new WebSocket(
      `${WEBSOCKET_URL}websocket/notifications?appType=${APP_TYPE}`
    );

    thopSocketRef.current = socket;

    socket.onmessage = (event) => {
      const message = JSON.parse(event?.data);
      if (
        message?.messageType === thopNotificationAlertType?.acceptCareplan ||
        message?.messageType === thopNotificationAlertType?.rejectCareplan
      ) {
        dispatch(addThopNotificationMessage(message));
        dispatch(setStatusUpdateCount());
      }
    };

    socket.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    socket.onclose = (event) => {
      console.log("WebSocket closure code:", event.code);
      if (event.code !== 1000 && event.code !== 1001) {
        console.log(
          "WebSocket closed abnormally. Reconnecting to WebSocket server...",
          event
        );
        createThopNotificationSocketConnection();
      }
    };
  };

  const closeThopNotificationSocketConnection = () => {
    if (thopSocketRef?.current) {
      thopSocketRef?.current?.close(1000, "Client disconnected");
      thopSocketRef.current = null;
    }
  };

  useEffect(() => {
    if (
      selectedPatientIndex !== null &&
      criticalBedList[selectedPatientIndex]
    ) {
      const patient_id = criticalBedList[selectedPatientIndex]?.patient_id;
      dispatch(setPatientUHID(patient_id));

      patientRefs.current[selectedPatientIndex]?.scrollIntoView({
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatientIndex, criticalBedList]);

  useEffect(() => {
    const fetchAllBeds = async () => {
      if (patientDetails.masterPatientDetails.length) return;
      patientDetails.onlyLocations?.map(async (location) => {
        try {
          const payload = {
            intent: "getCriticalBedsByLocation",
            state: { locationId: location },
          };
          const response = await fetch(createApiUrl(CRITICAL_BEDS_ID), {
            method: "POST",
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
              "Content-Type": "application/json",
              version: "WORKING",
            },
            body: JSON.stringify(payload),
          });
          if (!response.ok) {
            throw new Error(`Error fetching critical beds for all locations`);
          }
          const data = await response.json();
          const patientData = data?.bed?.map((item: iCriticalBedList) => {
            return {
              patientId: item?.patient_id,
              patientName: titleCase(item?.patient_name),
              location: item?.location_id,
            };
          });
          dispatch(setMasterPatientDetails(patientData));
        } catch (error) {
          console.error(error);
        }
      });
    };

    fetchAllBeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientDetails.masterPatientDetails.length,
    patientDetails.onlyLocations,
  ]);

  useEffect(() => {
    if (isInitialRenderForPatient) {
      setIsInitialRenderForPatient(false);
      return;
    }
    setSearchQuery("");
    dispatch(setHasPatientDataLoaded(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails?.currentLocation, isInitialRenderForPatient]);

  useEffect(() => {
    if (isInitialRenderForSearch) {
      setIsInitialRenderForSearch(false);
      return;
    }
    if (selectedPatientIndex !== null) {
      patientCardRef?.current?.children?.[
        selectedPatientIndex
      ]?.classList?.remove("patient-card-selected");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails?.searchQuery, isInitialRenderForSearch]);

  useEffect(() => {
    fetchGetDoctors();
    fetchGetLocations();
    createThopNotificationSocketConnection();

    const thopSocketInterval = setInterval(() => {
      if (
        thopSocketRef?.current &&
        thopSocketRef?.current?.readyState === WebSocket.OPEN
      ) {
        const emptyMessage = {
          patientID: "Dummy Patient",
          messageType: "dummy-message",
          patientLocation: "dummy-location",
          message: "Dummy message sent successfully",
        };
        thopSocketRef?.current?.send(JSON.stringify(emptyMessage));
      }
    }, 50000);
    return () => {
      clearInterval(thopSocketInterval);
      closeThopNotificationSocketConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hospitals">
      {patientDetails.layout === "none" && (
        <div id="select-layout-container" className="select-layout">
          <Select
            sx={{ width: "170px" }}
            id="select-layout-menu"
            value={patientDetails.layout}
            onChange={(e: SelectChangeEvent) =>
              dispatch(setLayout(e.target.value))
            }
            variant="outlined"
          >
            {layoutOptions?.map((item) => {
              return (
                <MenuItem
                  id="select-layout-item"
                  value={item?.value}
                  key={item?.value}
                >
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      )}
      {patientDetails.layout !== "none" &&
        patientDetails.layout !== "patientDashboard" && (
          <Grid container columns={16}>
            <Grid item xs={16} sm={8} md={4} lg={3} xl={2}>
              <Select
                id="select-location-menu"
                value={patientDetails.currentLocation}
                onChange={(e: SelectChangeEvent) => {
                  dispatch(setCurrentLocation(e.target.value));
                  dispatch(setSelectedPatientIndex(null));
                }}
                IconComponent={CustomArrowIcon}
                className="select-location"
              >
                {patientDetails.onlyLocations?.map((item) => (
                  <MenuItem
                    id="select-location-menu-item"
                    value={item}
                    key={item}
                  >
                    <div
                      id="select-location-box"
                      className="location-default-item"
                    >
                      <FmdGoodOutlinedIcon
                        id="location-icon"
                        style={{ color: "#1b5299" }}
                      />
                      <span id="location-name" className="location-list-item">
                        {item}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={16} sm={8} md={4} lg={3} xl={2}>
              <TextField
                fullWidth
                id="search-patient"
                placeholder={hospitals.serachPlaceholder}
                value={patientDetails?.searchQuery}
                onChange={handleSearchChange}
                className="search-bar"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: { height: "65px", padding: "0 14px" },
                }}
              />
            </Grid>
            <Grid item xs={16} sm={16} md={8} lg={10} xl={12}>
              <div id="patient-lists" className="patient-list-container">
                <div
                  id="patient-list-item"
                  className="patient-section"
                  ref={patientCardRef}
                >
                  {filteredBedList?.length ? (
                    <>
                      {filteredBedList?.map((patient, index) => {
                        return (
                          <div
                            id="individual-patient-card"
                            key={patient.id}
                            ref={(el) => (patientRefs.current[index] = el)}
                            className={`patient-card ${
                              index === selectedPatientIndex
                                ? "patient-card-selected"
                                : ""
                            }`}
                            onClick={() =>
                              handleSelectedPatient(patient, index)
                            }
                          >
                            <div
                              id="patient-logo-container"
                              className="patient-image"
                            >
                              <AccountCircleIcon
                                id="patient-logo"
                                className="patient-logo"
                                fontSize="large"
                              />
                            </div>
                            <div
                              id="patient-details"
                              className="patient-card-details"
                            >
                              <Box
                                id="patient-name-and-id"
                                sx={{ typography: "body2" }}
                              >
                                {titleCase(patient?.patient_name)}&nbsp;-&nbsp;
                                {patient?.patient_id}
                              </Box>
                              <Box
                                id="patient-admission-date"
                                sx={{ typography: "subtitle2" }}
                              >
                                {admDate}:&nbsp;
                                {getDate(patient?.admission_date)}
                              </Box>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      id="no-patient-available-text"
                      className="no-patient-available"
                    >
                      {noPatientAvailableText}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      {patientDetails.layout === "patientDashboard" ? (
        <Suspense fallback={<LoadingComp />}>
          <PatientDashboard />
        </Suspense>
      ) : null}
      {patientDetails.patient_data_loaded &&
      patientDetails.layout === "vitals" ? (
        <>
          <PatientDetails />
          <VitalsContainer />
        </>
      ) : null}
      {patientDetails.patient_data_loaded &&
      patientDetails.layout === "patientData" ? (
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <PatientDetails />
            <PatientReports />
          </Grid>
        </Grid>
      ) : null}
      {patientDetails.patient_data_loaded &&
      patientDetails.layout === "intensivists" ? (
        <>
          <PatientDetails />
          <Suspense fallback={<LoadingComp />}>
            <TreatmentAndMedications socketRef={thopSocketRef} />
          </Suspense>
        </>
      ) : null}
      {patientDetails.patient_data_loaded &&
      patientDetails.layout === "default" ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <PatientDetails />
            <VitalsContainer />
            <PatientReports />
            <Suspense fallback={<LoadingComp />}>
              <TreatmentAndMedications socketRef={thopSocketRef} />
            </Suspense>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default Hospitals;
